import React, { useState, useEffect } from 'react';
import { Upload, Mic, Sliders, DollarSign, Globe, Type, InfoIcon } from 'lucide-react';
import './App.css';

const App = () => {
  const [file, setFile] = useState(null);
  const [apiKey, setApiKey] = useState('');
  const [voice, setVoice] = useState('');
  const [voices, setVoices] = useState([]);
  const [stability, setStability] = useState(0.5);
  const [similarityBoost, setSimilarityBoost] = useState(0.5);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isManualInput, setIsManualInput] = useState(false);
  const [manualText, setManualText] = useState('');
  const [language, setLanguage] = useState('en');

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Spanish' },
    { code: 'fr', name: 'French' },
    { code: 'de', name: 'German' },
    { code: 'it', name: 'Italian' },
    { code: 'pt', name: 'Portuguese' },
    { code: 'pl', name: 'Polish' },
    { code: 'hi', name: 'Hindi' },
    { code: 'ja', name: 'Japanese' },
    { code: 'ko', name: 'Korean' },
  ];

  useEffect(() => {
    if (apiKey) {
      fetchVoices();
    }
  }, [apiKey]);

  const fetchVoices = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await fetch(`/api/voices?api_key=${encodeURIComponent(apiKey)}`);
      if (!response.ok) {
        throw new Error('Failed to fetch voices. Please check your API key.');
      }
      const data = await response.json();
      setVoices(data);
    } catch (error) {
      console.error('Error fetching voices:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isManualInput && !file) {
      setError('Please select a file or switch to manual text input');
      return;
    }
    if (isManualInput && !manualText.trim()) {
      setError('Please enter some text');
      return;
    }
    setIsLoading(true);
    setError(null);
    setSuccess(null);

    const formData = new FormData();
    formData.append('api_key', apiKey);
    formData.append('voice', voice);
    formData.append('stability', stability);
    formData.append('similarity_boost', similarityBoost);
    formData.append('language', language);

    if (isManualInput) {
      formData.append('text', manualText);
    } else {
      formData.append('file', file);
    }

    try {
      const response = await fetch('/api/convert', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Conversion failed');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'output.mp3';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setSuccess('Audio file generated and downloaded successfully!');
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDonate = () => {
    const paypalDonateUrl = "https://www.paypal.com/donate/?hosted_button_id=CCVEF2F43MDGU";
    window.open(paypalDonateUrl, '_blank');
  };

  return (
    <div className="container">
      <h1 className="title">Text to Speech Converter</h1>
      {error && <div className="error">{error}</div>}
      {success && <div className="success">{success}</div>}
      <div className="info-message">
        <InfoIcon className="icon" />
        <p>The language of the output speech is automatically detected based on your input text and the selected voice. The language selector below is a guide for text input.</p>
      </div>
      <form onSubmit={handleSubmit} className="form">
        <div className="input-group">
          <label htmlFor="api-key">ElevenLabs API Key:</label>
          <input
            id="api-key"
            type="text"
            value={apiKey}
            onChange={(e) => setApiKey(e.target.value)}
            placeholder="Enter your API Key"
            required
          />
        </div>
        <div className="input-group radio-group">
          <label>
            <input
              type="radio"
              checked={!isManualInput}
              onChange={() => setIsManualInput(false)}
            />
            Upload File
          </label>
          <label>
            <input
              type="radio"
              checked={isManualInput}
              onChange={() => setIsManualInput(true)}
            />
            Manual Text Input
          </label>
        </div>
        {isManualInput ? (
          <div className="input-group">
            <label htmlFor="manual-text">
              <Type className="icon" />
              Enter Text:
            </label>
            <textarea
              id="manual-text"
              value={manualText}
              onChange={(e) => setManualText(e.target.value)}
              placeholder="Enter your text here"
              required
              className="manual-text-input"
            />
          </div>
        ) : (
          <div className="input-group">
            <label htmlFor="file-upload" className="file-upload-label">
              <Upload className="icon" />
              Upload Document
            </label>
            <input
              id="file-upload"
              type="file"
              onChange={handleFileChange}
              accept=".pdf,.doc,.docx"
              required={!isManualInput}
              className="file-upload-input"
            />
            {file && <span className="file-name">{file.name}</span>}
          </div>
        )}
        <div className="input-group">
          <label htmlFor="language">
            <Globe className="icon" />
            Select Input Language Guide:
          </label>
          <select
            id="language"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            {languages.map((lang) => (
              <option key={lang.code} value={lang.code}>{lang.name}</option>
            ))}
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="voice">Select Voice:</label>
          <select
            id="voice"
            value={voice}
            onChange={(e) => setVoice(e.target.value)}
            required
          >
            <option value="">Select a voice</option>
            {voices.map((v) => (
              <option key={v.voice_id} value={v.voice_id}>{v.name}</option>
            ))}
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="stability">
            <Sliders className="icon" />
            Stability: {stability}
          </label>
          <input
            id="stability"
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={stability}
            onChange={(e) => setStability(parseFloat(e.target.value))}
          />
        </div>
        <div className="input-group">
          <label htmlFor="similarity-boost">
            <Sliders className="icon" />
            Similarity Boost: {similarityBoost}
          </label>
          <input
            id="similarity-boost"
            type="range"
            min="0"
            max="1"
            step="0.1"
            value={similarityBoost}
            onChange={(e) => setSimilarityBoost(parseFloat(e.target.value))}
          />
        </div>
        <button type="submit" className="submit-btn" disabled={isLoading}>
          <Mic className="icon" />
          {isLoading ? 'Converting...' : 'Convert to Speech'}
        </button>
      </form>
      <button onClick={handleDonate} className="donate-btn">
        <DollarSign className="icon" />
        Donate via PayPal
      </button>
    </div>
  );
};

export default App;
